<template>
<div>
	<v-carousel
        cycle
        height="400"
        hide-delimiter-background
        :show-arrows="false"
        hide-delimiters
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn
            color="success"
            v-bind="attrs"
            v-on="on"
        >Previous slide
        </v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn
            color="info"
            v-bind="attrs"
            v-on="on"
        >Next slide
        </v-btn>
      </template>

      <v-carousel-item
          src="../assets/banner001.jpg"
      >
        <v-sheet
            color="rgb(14,127,159,0.9)"
            height="100%"
        >
          <v-row
              class="fill-height"
              align="center"
              justify="center"

              style="margin-left: 10px;margin-right: 10px;background-color: transparent"
          >

            <div :class="$vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4'"
                 style="font-family: 'fantasy';font-weight: bold;text-align: center;line-height: 50px">
              <h1>{{ countryName.toUpperCase() }} <span style="color: yellow">Real Estate</span> Agents
              </h1>
              <v-row>
              <v-col align-self="center" md="6" offset-md="3">
              <v-text-field
                  style="opacity: 0.9;margin-top: 50px;border-width: 3px!important;"
                  :placeholder="$t('searchKeyword')"
                  rounded
                  outlined
                  clearable
                  v-model="params.search"
                  append-icon="mdi-magnify"
                  @click.stop="noFn()"
                  @click:append.stop="handleSearch()"
                  v-shortkey="['enter']"
                  @shortkey.native="handleSearch()"
                  @click:clear="clearSearch()"
              >
              </v-text-field>
              </v-col>
              </v-row>
            </div>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
	<v-container>
		<v-row>
			<v-col cols="12" md="4" lg="3" sm="6"
				v-for="d in agentsList"
             	:key="d._id"
			>
			<v-hover
				v-slot="{ hover }"
				close-delay="200"
			>
				<v-card :to="'/agent/'+ d._id" :elevation="hover ? 6 : 2" :class="{ 'on-hover': hover }" class="mx-auto animate__animated animate__fadeInRight animate__duration-5s">
					<v-list-item-content class="justify-center">
						<div class="mx-auto text-center">
						<v-avatar
							color="brown"
							size="150"
						>
							<img
								v-if="d.photo===''  || d.photo===undefined"
								:alt="d.name"
								style="background-size: contain"
								src="@/assets/user.png"
							/>
							<img
								:alt="d.name"
								v-else
								style="background-size: contain"
								:src="d.photo"
							/>
						</v-avatar>
						<h3 class="my-3">{{ d.name }}</h3>
						<p class="text-caption">
							Tel: {{ d.phoneNumber }}
						</p>
						<p class="text-caption">
							Property: {{ d.totalProperty }}
						</p>
            <p class="text-caption" style="min-height: 24px">
							<v-icon color="green" v-if="d.address">mdi-map-marker</v-icon>
							{{d.address && (d.address.length > 20 ? d.address.substring(0,20)+"..." : d.address)}}
						</p>
						</div>
					</v-list-item-content>
				</v-card>
			</v-hover>
			</v-col>
      <loading-circle v-if="loading"></loading-circle>
      <v-col v-if="totalPage > 0" cols="12" style="text-align: center">
        <v-pagination
          v-model="page"
          :length="Math.ceil(totalPage / 12)"
          :total-visible="9"
          circle
        ></v-pagination>
      </v-col>
      <v-col v-else cols="12" style="text-align: center">
        <p>Sorry, no agent found!</p>
      </v-col>
		</v-row>
	</v-container>
</div>
</template>
<script>
import {countyMobileList} from "@/libs/countryMobileWithFlag";
import MainMixin from "@/mixins/mainMixin";
import _ from "lodash";
import loadingCircle from "../components/loadingCircle.vue"

export default {
  name: "Home",
  mixins: [MainMixin],
  mounted() {
    this.$jQuery("body").off();
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  components: {loadingCircle},
  data() {
    return {
      eager: true,
      windowHeight: window.innerHeight,
      countryName: "CAMBODIA",
      params: {
        locationId: "",
        type: "",
        search: "",
        minPrice: "",
        maxPrice: "",
        groupType: "",
        countryId: ""
      },
      renderInfoWindow: true,
      loading: true,
      page: 1,
      totalPage: 0,
      skip: 1,
      limit: 12
    };
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderInfoWindow = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderInfoWindow = true;
      });
    },
    noFn() {
    },
    clearSearch(){
      let vm = this;
      vm.params.search="";
    },
    handleSearch() {
      let vm = this;
      vm.loading = true;
      vm.page = 1;
      vm.queryAgents(vm.params.search);
      vm.forceRerender();
    },
    queryAgents: _.debounce(function(search, skip, limit, countryId) {
      let vm = this;
      vm.fetchAgents({
        search: search || "",
        skip: skip || 1,
        limit: limit || 12,
        sortBy: vm.sortBy || "",
        sortDesc: vm.sortDesc || "",
        countryId: countryId || "",
      });
      vm.loading = false;
    }, 500)
  },
  watch: {
    page(val) {
      let vm = this;
      vm.loading = true;
      vm.skip = val;
      vm.limit = 12;
      vm.countryId = "";
      vm.queryAgents(vm.params.search, vm.skip, vm.limit, vm.countryId);
    },
    "params.search"(val) {
      let vm = this;
      if(val === ""){
        vm.page = 1;
        vm.loading = true;
        vm.queryAgents(vm.params.search, vm.skip, vm.limit, vm.countryId);
      }
    }
  },
  created() {
    let vm = this;
    if (!(localStorage.country !== undefined && localStorage.country !== "")) {
      vm.$jQuery.get("https://www.cloudflare.com/cdn-cgi/trace", function (
          data
      ) {
        if (data) {
          vm.$store.state.country = data.split("loc=")[1].substr(0, 2);
          localStorage.country = vm.$store.state.country;
          let cDoc = countyMobileList.find(o => {
            return o.value === vm.$store.state.country || o.number === localStorage.country;
          });
          vm.params.countryId = cDoc.number;
          localStorage.countryCode = cDoc.value;
          vm.countryName = cDoc.name;
          vm.forceRerender();
        }
      });
    } else {
      let cDoc = countyMobileList.find(o => {
        return o.value === localStorage.country || o.number === localStorage.country;
      });
      vm.params.countryId = cDoc.number;
      localStorage.countryCode = cDoc.value;
      vm.countryName = cDoc.name;
      vm.forceRerender();
    }
    vm.queryAgents();
    const top = window.pageYOffset;
    this.currentPoint = top;
    let goPoint = this.currentPoint > 160 ? 135 : 0;
    this.$vuetify.goTo(goPoint);
  }
};
</script>

<style>

</style>

