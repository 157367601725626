var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-carousel',{attrs:{"cycle":"","height":"400","hide-delimiter-background":"","show-arrows":false,"hide-delimiters":""},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success"}},'v-btn',attrs,false),on),[_vm._v("Previous slide ")])]}},{key:"next",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info"}},'v-btn',attrs,false),on),[_vm._v("Next slide ")])]}}])},[_c('v-carousel-item',{attrs:{"src":require("../assets/banner001.jpg")}},[_c('v-sheet',{attrs:{"color":"rgb(14,127,159,0.9)","height":"100%"}},[_c('v-row',{staticClass:"fill-height",staticStyle:{"margin-left":"10px","margin-right":"10px","background-color":"transparent"},attrs:{"align":"center","justify":"center"}},[_c('div',{class:_vm.$vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4',staticStyle:{"font-family":"'fantasy'","font-weight":"bold","text-align":"center","line-height":"50px"}},[_c('h1',[_vm._v(_vm._s(_vm.countryName.toUpperCase())+" "),_c('span',{staticStyle:{"color":"yellow"}},[_vm._v("Real Estate")]),_vm._v(" Agents ")]),_c('v-row',[_c('v-col',{attrs:{"align-self":"center","md":"6","offset-md":"3"}},[_c('v-text-field',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['enter']),expression:"['enter']"}],staticStyle:{"opacity":"0.9","margin-top":"50px","border-width":"3px!important"},attrs:{"placeholder":_vm.$t('searchKeyword'),"rounded":"","outlined":"","clearable":"","append-icon":"mdi-magnify"},on:{"click":function($event){$event.stopPropagation();return _vm.noFn()},"click:append":function($event){$event.stopPropagation();return _vm.handleSearch()},"click:clear":function($event){return _vm.clearSearch()}},nativeOn:{"shortkey":function($event){return _vm.handleSearch()}},model:{value:(_vm.params.search),callback:function ($$v) {_vm.$set(_vm.params, "search", $$v)},expression:"params.search"}})],1)],1)],1)])],1)],1)],1),_c('v-container',[_c('v-row',[_vm._l((_vm.agentsList),function(d){return _c('v-col',{key:d._id,attrs:{"cols":"12","md":"4","lg":"3","sm":"6"}},[_c('v-hover',{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto animate__animated animate__fadeInRight animate__duration-5s",class:{ 'on-hover': hover },attrs:{"to":'/agent/'+ d._id,"elevation":hover ? 6 : 2}},[_c('v-list-item-content',{staticClass:"justify-center"},[_c('div',{staticClass:"mx-auto text-center"},[_c('v-avatar',{attrs:{"color":"brown","size":"150"}},[(d.photo===''  || d.photo===undefined)?_c('img',{staticStyle:{"background-size":"contain"},attrs:{"alt":d.name,"src":require("@/assets/user.png")}}):_c('img',{staticStyle:{"background-size":"contain"},attrs:{"alt":d.name,"src":d.photo}})]),_c('h3',{staticClass:"my-3"},[_vm._v(_vm._s(d.name))]),_c('p',{staticClass:"text-caption"},[_vm._v(" Tel: "+_vm._s(d.phoneNumber)+" ")]),_c('p',{staticClass:"text-caption"},[_vm._v(" Property: "+_vm._s(d.totalProperty)+" ")]),_c('p',{staticClass:"text-caption",staticStyle:{"min-height":"24px"}},[(d.address)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-map-marker")]):_vm._e(),_vm._v(" "+_vm._s(d.address && (d.address.length > 20 ? d.address.substring(0,20)+"..." : d.address))+" ")],1)],1)])],1)]}}],null,true)})],1)}),(_vm.loading)?_c('loading-circle'):_vm._e(),(_vm.totalPage > 0)?_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"length":Math.ceil(_vm.totalPage / 12),"total-visible":9,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('p',[_vm._v("Sorry, no agent found!")])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }